<template>
  <div>
    <loading></loading>
  </div>
</template>

<script>
export default {
  methods: {
    GET_USER_DEVICE() {
      const ua = navigator.userAgent;
      const device = {
        iPad: /iPad/.test(ua),
        iPhone: /iPhone/.test(ua),
        MacOS: /Macintosh/.test(ua),
        Android4: /Android 4/.test(ua),
      };

      if (device.iPad || device.iPhone || device.MacOS) {
        location.href = "https://apps.apple.com/tr/app/rezzapp/id6737267934";
      } else {
        location.href =
          "https://play.google.com/store/apps/details?id=com.rezzapp";
      }
    },
  },
  mounted() {
    this.GET_USER_DEVICE();
  },
};
</script>

<style></style>
